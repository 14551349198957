/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import { graphql } from "react-apollo";
// import axios from "axios";
import Cryptr from "cryptr";
import getRmsPaymentQuery from "../schema/getRmsPaymentQuery";
// import { apiAuthToken } from "../../../common/config";

const cryptr = new Cryptr("chickenPie");

const InfoBox = styled.div`
  text-align: center;
`;

const PaymentTableHead = styled.th`
  color: #fff !important;
  text-align: center !important;
`;

const PaymentNumberCell = styled.td`
  text-align: right;
  vertical-align: middle !important;
`;
const PaymentNumberTotal = styled.th`
  text-align: right !important;
`;
const PaymentDateCell = styled.td`
  text-align: center;
  vertical-align: middle !important;
`;

class PaymentTable extends Component {
  state = {
    modalShow: false,
    loading: false,
    warningShow: "none",
  };

  openModal() {
    this.setState({ modalShow: true });
  }

  closeModal(type) {
    switch (type) {
      case 1:
        this.setState({ modalShow: false });
        break;
      default:
        break;
    }
  }

  directDebitInfo() {
    return (
      <InfoBox>
        <div className="content">
          <h3>กรุณานำเงินเข้าบัญชีของท่านก่อนถึงรอบตัดบัญชีรอบถัดไป</h3>
          <p>สอบถามข้อมูลเพิ่มเติม โทร 020 300 000 กด 1</p>
        </div>
      </InfoBox>
    );
  }

  recurringInfo(directType) {
    if (directType === "DD") {
      return (
        <InfoBox>
          <div className="content">
            <h3>กรุณานำเงินเข้าบัญชีของท่านก่อนถึงรอบตัดบัญชีรอบถัดไป</h3>
            <p>สอบถามข้อมูลเพิ่มเติม โทร 020 300 000 กด 1</p>
          </div>
        </InfoBox>
      );
    } else if (directType === "RC" || directType === "DC") {
      return (
        <InfoBox>
          <div className="content">
            <h3>กรุณาติดต่อ Call Center</h3>
            <p>สอบถามข้อมูลเพิ่มเติม โทร 020 300 000 กด 1</p>
          </div>
        </InfoBox>
      );
    }
  }

  createObjectURL(file) {
    if (window.webkitURL) {
      return window.webkitURL.createObjectURL(file);
    } else if (window.URL && window.URL.createObjectURL) {
      return window.URL.createObjectURL(file);
    } else {
      return null;
    }
  }

  openBillPayment(contractId) {
    this.setState({ loading: true });

    const encryptedContractId = cryptr.encrypt(contractId);

    document.forms.paymentBill.action = `https://api.myorigin.net/billpayment/${encryptedContractId}`;
    document.forms.paymentBill.submit();

    this.setState({ loading: false });
  }

  openReceipt({ receiptId, pdfFile, project }) {
    this.setState({ loading: true });
    if (!pdfFile) {
      // if (projectOne) {
      //   const encryptedReceiptId = cryptr.encrypt(receiptId);
      //   document.forms.paymentReceipt.action = `http://localhost:3001/ereceiptOne/${encryptedReceiptId}`;
      //   document.forms.paymentReceipt.submit();
      // }
      if (project.projectType === "C") {
        // const encryptedReceiptId = cryptr.encrypt(receiptId);
        document.forms.paymentReceipt.action = `http://oricrm.origin.co.th/PDFCreater/page/receiptfile.aspx?rcid=${receiptId}`;
        document.forms.paymentReceipt.submit();
      }
      if (project.projectType === "H") {
        // const encryptedReceiptId = cryptr.encrypt(receiptId);
        document.forms.paymentReceipt.action = `http://btncrm.origin.co.th/PDFCreater/page/receiptfile.aspx?rcid=${receiptId}`;

        document.forms.paymentReceipt.submit();
      }
    } else {
      document.forms.paymentReceipt.action = `https://api.myorigin.net/static/payment/${pdfFile}`;
      document.forms.paymentReceipt.submit();
    }

    this.setState({ loading: false });
  }

  render() {
    if (this.props.data.loading) {
      return null;
    }

    if (!this.props.data.getRmsCustomerContractMyById) {
      return null;
    }

    const {
      selectContract,
      data: { getRmsCustomerContractMyById },
    } = this.props;

    const { downPayment } = getRmsCustomerContractMyById;
    // console.log(
    //   "getRmsCustomerContractMyById-->",
    //   getRmsCustomerContractMyById
    // );
    let paymentData = downPayment ? downPayment : [];

    if (getRmsCustomerContractMyById.id === "SO-BTN02-19090015") {
      paymentData = [
        {
          amount: 5000,
          contractId: "SO-BTN02-19090015",
          id: "00001",
          paymentDate: "2018-10-05T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-18100021",
          receiptNumber: null,
          termAmount: 5000,
          termDueDate: "2018-10-05T07:00:00+07:00",
          termId: "34",
          termName: "เงินจอง",
          pdfFile: "57_100.pdf",
        },
        {
          amount: 5000,
          contractId: "SO-BTN02-19090015",
          id: "00002",
          paymentDate: "2018-10-05T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-18100021",
          receiptNumber: null,
          termAmount: 5000,
          termDueDate: "2018-10-05T07:00:00+07:00",
          termId: "35",
          termName: "เงินทำสัญญา",
          pdfFile: "57_100.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00003",
          paymentDate: "2018-11-08T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-18110009",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2018-11-05T07:00:00+07:00",
          termId: "D01",
          termName: "เงินดาวน์งวดที่ 1",
          pdfFile: "57_201.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00004",
          paymentDate: "2018-12-10T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-18120045",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2018-12-05T07:00:00+07:00",
          termId: "D02",
          termName: "เงินดาวน์งวดที่ 2",
          pdfFile: "57_202.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00005",
          paymentDate: "2019-01-05T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19010017",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-01-05T07:00:00+07:00",
          termId: "D03",
          termName: "เงินดาวน์งวดที่ 3",
          pdfFile: "57_203.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00006",
          paymentDate: "2019-02-06T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19020012",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-02-05T07:00:00+07:00",
          termId: "D04",
          termName: "เงินดาวน์งวดที่ 4",
          pdfFile: "57_204.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00007",
          paymentDate: "2019-03-08T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19030025",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-03-05T07:00:00+07:00",
          termId: "D05",
          termName: "เงินดาวน์งวดที่ 5",
          pdfFile: "57_205.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00008",
          paymentDate: "2019-04-08T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19040013",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-04-05T07:00:00+07:00",
          termId: "D06",
          termName: "เงินดาวน์งวดที่ 6",
          pdfFile: "57_206.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "00009",
          paymentDate: "2019-05-05T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19050020",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-05-05T07:00:00+07:00",
          termId: "D07",
          termName: "เงินดาวน์งวดที่ 7",
          pdfFile: "57_207.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "0010",
          paymentDate: "2019-06-08T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19060019",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-06-05T07:00:00+07:00",
          termId: "D08",
          termName: "เงินดาวน์งวดที่ 8",
          pdfFile: "57_208.pdf",
        },
        {
          amount: 30000,
          contractId: "SO-BTN02-19090015",
          id: "0011",
          paymentDate: "2019-07-06T07:00:00+07:00",
          paymentType: "CR",
          receiptId: "RV-BTN02-19070032",
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-07-05T07:00:00+07:00",
          termId: "D09",
          termName: "เงินดาวน์งวดที่ 9",
          pdfFile: "57_209.pdf",
        },
        {
          amount: null,
          contractId: "SO-BTN02-19090015",
          id: "0012",
          paymentDate: null,
          paymentType: null,
          receiptId: null,
          receiptNumber: null,
          termAmount: 30000,
          termDueDate: "2019-08-05T07:00:00+07:00",
          termId: "D10",
          termName: "เงินดาวน์งวดที่ 10",
        },
        {
          amount: null,
          contractId: "SO-BTN02-19090015",
          id: "0013",
          paymentDate: null,
          paymentType: "CR",
          receiptId: "",
          receiptNumber: null,
          termAmount: 0,
          termDueDate: "",
          termId: "D11",
          termName: "เงินดาวน์งวดที่ 11",
        },
        {
          amount: null,
          contractId: "SO-BTN02-19090015",
          id: "0014",
          paymentDate: null,
          paymentType: "CR",
          receiptId: "",
          receiptNumber: null,
          termAmount: 0,
          termDueDate: "",
          termId: "D12",
          termName: "เงินดาวน์งวดที่ 12",
        },
        {
          amount: null,
          contractId: null,
          id: "41a7c850-6126-4d94-b06b-8679c4c82e54",
          paymentDate: null,
          paymentType: null,
          receiptId: null,
          receiptNumber: null,
          termAmount: 5690000,
          termDueDate: "2019-09-27T07:00:00+07:00",
          termId: "66",
          termName: "เงินโอนกรรมสิทธิ์",
        },
      ];
    }

    const payments =
      paymentData.reduce((pre, cur) => {
        const tempPre = [...pre];

        const findTermDup = tempPre.findIndex(
          (data) => data.termId === cur.termId
        );

        if (findTermDup === -1) {
          tempPre.push({
            id: cur.id,
            termId: cur.termId,
            termName: cur.termName,
            termDueDate: cur.termDueDate,
            termAmount: cur.termAmount,
            receipt: [
              {
                id: cur.id,
                receiptNumber: cur.receiptNumber,
                receiptId: cur.receiptId,
                paymentDate: cur.paymentDate,
                amount: cur.amount,
                paymentType: cur.paymentType,
              },
            ],
            pdfFile: cur.pdfFile,
          });
        } else {
          tempPre[findTermDup].receipt.push({
            id: cur.id,
            receiptNumber: cur.receiptNumber,
            receiptId: cur.receiptId,
            paymentDate: cur.paymentDate,
            amount: cur.amount,
            paymentType: cur.paymentType,
          });
        }

        return tempPre;
      }, []) || [];

    // console.log("payments-->", payments);
    const totalAmount = payments.reduce(
      (pre, cur) =>
        pre + cur.receipt.reduce((pre2, cur2) => pre2 + cur2.amount, 0),
      0
    );
    const totalTermAmount = payments.reduce(
      (pre, cur) => pre + cur.termAmount,
      0
    );

    const modalClass = classNames({
      modal: true,
      "is-active": this.state.modalShow,
    });
    const { projectName = "", unitNumber = "" } = selectContract;

    const resultRows = payments.reduce((pre, cur) => {
      const tempPre = [...pre];
      tempPre.push(
        <tr key={cur.id}>
          <td style={{ verticalAlign: "middle" }} rowSpan={cur.receipt.length}>
            {cur.termName}
          </td>
          <PaymentDateCell rowSpan={cur.receipt.length}>
            {cur.termDueDate === ""
              ? "-"
              : moment(cur.termDueDate).format("DD/MM/YYYY")}
          </PaymentDateCell>
          <PaymentNumberCell rowSpan={cur.receipt.length}>
            {cur.termAmount.toLocaleString()}
          </PaymentNumberCell>

          <PaymentDateCell>
            {cur.receipt[0].paymentDate
              ? moment(cur.receipt[0].paymentDate).format("DD/MM/YYYY")
              : ""}
          </PaymentDateCell>
          <PaymentNumberCell>
            {cur.receipt[0].amount == null
              ? ""
              : cur.receipt[0].amount.toString().length < 4
              ? cur.receipt[0].amount
              : cur.receipt[0].amount.toLocaleString()}
          </PaymentNumberCell>
          <td>{cur.receipt[0].receiptId}</td>
          <PaymentDateCell>
            {cur.receipt[0].paymentType == null ? (
              moment(cur.termDueDate).isBefore(moment().add(1, "month")) ? (
                <a
                  className="button is-text"
                  onClick={() => {
                    this.props.directType !== "DD" &&
                    this.props.directType !== "RC" &&
                    this.props.directType !== "DC"
                      ? this.openBillPayment(this.props.contractId)
                      : this.openModal();
                  }}
                >
                  คลิ๊กเพื่อจ่ายเงิน
                </a>
              ) : (
                ""
              )
            ) : cur.receipt[0].paymentType === "YM" ? (
              <div style={{ fontSize: "13px", color: "#1496ED" }}> </div>
            ) : cur.receipt[0].paymentDate &&
              cur.receipt[0].receiptId == null ? (
              <div style={{ fontSize: "13px", color: "#1496ED" }}>
                อยู่ระหว่างจัดทำใบเสร็จ
              </div>
            ) : (
              cur.receipt[0].receiptId && (
                <button
                  type="button"
                  className="button is-info is-small"
                  onClick={() => {
                    this.openReceipt({
                      receiptId: cur.receipt[0].receiptId,
                      pdfFile: cur.pdfFile,
                      project: getRmsCustomerContractMyById.project,
                      // projectOne: allOneRmsProjectsNotCheck
                      //   .map((p) => p.id)
                      //   .includes(getRmsCustomerContractMyById.project.id),
                    });
                  }}
                  disabled={this.state.loading}
                >
                  <span className="icon">
                    <i
                      className={`fa ${
                        this.state.loading ? "fa-hourglass" : "fa-download"
                      }`}
                    />
                  </span>
                </button>
              )
            )}
          </PaymentDateCell>
        </tr>
      );

      if (cur.receipt.slice(1).length > 0) {
        const receiptRow = cur.receipt.slice(1).map((data) => (
          <tr key={data.id} row={cur.receipt.length - 1}>
            <PaymentDateCell>
              {data.paymentDate
                ? moment(data.paymentDate).format("DD/MM/YYYY")
                : ""}
            </PaymentDateCell>
            <PaymentNumberCell>
              {data.amount.toLocaleString()}
            </PaymentNumberCell>
            <td>{data.receiptId}</td>
            <PaymentDateCell>
              {data.paymentType == null ? (
                ""
              ) : data.paymentType === "YM" ? (
                <div style={{ fontSize: "13px", color: "#1496ED" }}>
                  เงินยกมาจากการย้ายห้อง
                </div>
              ) : data.paymentDate && data.receiptId == null ? (
                <div style={{ fontSize: "13px", color: "#1496ED" }}>
                  อยู่ระหว่างจัดทำใบเสร็จ
                </div>
              ) : (
                data.receiptId && (
                  <button
                    type="button"
                    className="button is-info is-small"
                    onClick={() => {
                      this.openReceipt({
                        receiptId: data.receiptId,
                        pdfFile: data.pdfFile,
                        project: getRmsCustomerContractMyById.project,
                        // projectOne: allOneRmsProjectsNotCheck
                        //   .map((p) => p.id)
                        //   .includes(getRmsCustomerContractMyById.project.id),
                      });
                    }}
                    disabled={this.state.loading}
                  >
                    <span className="icon">
                      <i
                        className={`fa ${
                          this.state.loading ? "fa-hourglass" : "fa-download"
                        }`}
                      />
                    </span>
                  </button>
                )
              )}
            </PaymentDateCell>
          </tr>
        ));
        tempPre.push(receiptRow);
      }
      return tempPre;
    }, []);

    return (
      <div>
        <div className="content">
          <div className="columns">
            <div className="column is-half-desktop">
              <h4>
                {projectName} ห้อง {unitNumber}
              </h4>
            </div>
          </div>
        </div>
        <article
          className="message is-warning"
          style={{ display: this.state.warningShow }}
        >
          <div className="message-body">
            <div className="columns">
              <div
                className="column is-one-fifth"
                style={{ textAlign: "center" }}
              >
                <div className="has-text-warning">
                  <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
                </div>
                <strong style={{ fontSize: "16px" }}>คำเตือน</strong>
              </div>
              <div
                className="column"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                กรุณาตรวจสอบอุปกรณ์ของคุณว่าได้ทำการอนุญาตให้แสดง popups ไว้แล้ว
              </div>
            </div>
          </div>
        </article>

        <div style={{ overflowX: "scroll" }}>
          <table className="table is-bordered" style={{ width: "100%" }}>
            <thead>
              <tr style={{ backgroundColor: "#32329c" }}>
                <PaymentTableHead style={{ width: "17%" }}>
                  ประเภทการชำระ
                </PaymentTableHead>
                <PaymentTableHead>วันที่ตามสัญญา</PaymentTableHead>
                <PaymentTableHead>จำนวนเงินตามสัญญา</PaymentTableHead>
                <PaymentTableHead>วันที่ชำระ</PaymentTableHead>
                <PaymentTableHead>จำนวนที่ชำระแล้ว</PaymentTableHead>
                <PaymentTableHead>เลขที่ใบเสร็จ</PaymentTableHead>
                <PaymentTableHead>Download ใบเสร็จ</PaymentTableHead>
              </tr>
            </thead>
            <tbody>{resultRows}</tbody>
            <tfoot>
              <tr style={{ backgroundColor: "#e5e5f2" }}>
                <th> </th>
                <PaymentNumberTotal>รวม</PaymentNumberTotal>
                <PaymentNumberTotal>
                  {totalTermAmount.toLocaleString()}
                </PaymentNumberTotal>
                <th> </th>
                <PaymentNumberTotal>
                  {totalAmount.toLocaleString()}
                </PaymentNumberTotal>
                <th> </th>
                <th> </th>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className={modalClass}>
          <div
            className="modal-background"
            onClick={() => this.closeModal(1)}
          />
          <div className="modal-card">
            <section className="modal-card-body">
              {this.recurringInfo(this.props.directType)}
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-small"
                onClick={() => this.closeModal(1)}
              >
                Close
              </button>
            </footer>
          </div>
        </div>

        <form id="paymentReceipt" method="get" target="_blank" />
        <form id="paymentBill" method="get" target="_blank" />
      </div>
    );
  }
}

export default graphql(getRmsPaymentQuery)(PaymentTable);
